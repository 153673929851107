// src/pages/About.js
import React from 'react';

function About() {
  return (
    <div>
      <h1>About Us</h1>
      <p>Information about the company and mission.</p>
    </div>
  );
}

export default About;
