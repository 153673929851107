// src/pages/Product.js
import React from 'react';

function Product() {
  return (
    <div>
      <h1>Our Product</h1>
      <p>Details about the hypertension product.</p>
    </div>
  );
}

export default Product;
